/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/Layout"

const ErrorPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading>Whoops! something went wrong</Heading>

          <Text variant="paragraph">It wasn&apos;t you, it&apos;s us...</Text>
          <Text variant="paragraph">
            Well it might be either of us, but who cares.
          </Text>

          <Text variant="paragraph">
            In anycase, the page you are trying to view no longer exists,
            perhaps take a look at our{" "}
            <Link to="/episode-002">latest episode instead</Link>
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default ErrorPage
